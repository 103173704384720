




























































import { Component, Vue, Watch } from "vue-property-decorator";

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

@Component({
  components: {},
})
export default class BannerComponent extends Vue {
  private img = require("@/assets/banner_desktop.webp");
  private aBanners: Array<dataBanner> = [];
  private slideIndex: number = 1;
  // private arrayBanner: Array<dataBanner> = [];

  private mounted() {
    this.getBanners();
    this.autoscroll();
  }

  private preloadImage(imageUrl) {
    var preloadLink = document.createElement("link");
    // Establece los atributos del enlace
    preloadLink.rel = "preload";
    preloadLink.as = "image"; 
    preloadLink.href = imageUrl; 
    document.head.appendChild(preloadLink);
  }

  private async getBanners() {
    this.$q.loading.show({
      // delay: 100, // ms
    });
    await this.$axios
      .get("slider/home")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.aBanners = res.data;
        if (this.$q.screen.xs) {
          this.preloadImage(base + res.data[0].imagen_mobile);
        } else {
          this.preloadImage(base + res.data[0].imagen);
        }
        for (let index = 0; index < this.aBanners.length; index++) {
          this.aBanners[index].imagen = base + this.aBanners[index].imagen;

          if (
            this.aBanners[index].imagen_mobile &&
            this.aBanners[index].imagen_mobile != ""
          ) {
            this.aBanners[index].imagen_mobile =
              base + this.aBanners[index].imagen_mobile;
          } else {
            this.aBanners[index].imagen_mobile = this.aBanners[index].imagen;
          }
          if (!this.aBanners[index].link) {
            this.aBanners[index].link = "#";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
      });
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 5000);
  }

  private updated() {
    this.showSlides(this.slideIndex);
  }
  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    }
  }
  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
}
